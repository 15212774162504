import * as React from 'react'
import Logo from "../images/footer-logo.svg";
import Footer from "../components/footer";
import Nav from "../components/nav";
import {Helmet} from "react-helmet";
import OpenGraphLogo from "../images/open-graph-logo.png"

function  Contact(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Contact Us</title>
                <meta property="og:image" content={OpenGraphLogo}></meta>
            </Helmet>
            <Nav/>
            <div className={"flex flex-col justify-center items-center max-w-5xl mx-auto"}>
                <h1 className={"text-center text-7xl py-16"}>Contact</h1>
                <img className={"h-14 w-14"} src={Logo} alt={"logo"} />
                <div className={"flex flex-row flex-wrap pt-16"}>
                    <div className={"max-w-xs"}>
                        <div className={"p-4"}>
                            <p className={"text-gray-500"}>TEL</p>
                            <p className={"text-2xl"}><a href={"tel:6196950688"}>619.695.0688</a></p>
                        </div>
                        <div className={"p-4"}>
                            <p className={"text-gray-500"}>EMAIL</p>
                            <p className={"text-2xl"}><a href={"mailto:info@kirehab.co"}>info@kirehab.com</a></p>
                        </div>
                        <div className={"p-4"}>
                            <p className={"text-gray-500"}>LOCATION</p>
                            <p className={"text-2xl"}><a href={"https://www.google.com/maps/place/Kinetic+Impact/@33.0154118,-117.1030547,17z/data=!3m1!4b1!4m5!3m4!1s0x80dbf7223b30f431:0xe912e4488ce34784!8m2!3d33.0154118!4d-117.1008607"}>10806 Willow CT,
                                San Diego, CA 92127</a></p>
                        </div>
                    </div>
                    <div className={"border border-accent p-12 "}>
                        <h1 className={"text-5xl"}>Drop us a note.</h1>
                        <p className={"text-gray-500 py-8"}>Got questions? We would love to help!</p>
                        <form className={"flex flex-col gap-4"} name={"contact"} method={"POST"} data-netlify={"true"}>
                            <input type="hidden" name="form-name" value="contact" />
                            <input className={"border active:border-accent border-black p-2 "} type="text" id="name" name="name" placeholder="Name" required/>
                            <input className={"border active:border-accent border-black p-2"} type="email" id="email" name="email" placeholder="Email" required/>
                            <input className={"border active:border-accent border-black p-2 pb-32"} type="text" id="message" name="message" placeholder="Note Or Message" required/>
                            <input className={"bg-black text-white p-2 text-2xl"} type="submit" value="Submit"/>
                        </form>

                    </div>
                </div>
            </div>

            <Footer/>

        </div>
    )
}

export default Contact